import { Roll } from '@/types/Roll';
import { JDR } from '@/types/Interfaces';

export class DamageRoll extends Roll {

    constructor(stat: string, playerName: string) {
        super(stat, JDR.ParceDomine, playerName);
    }

    roll(char: null, modifier: boolean, secret: boolean): void {
        let min = 0;
        let max = 0;
        switch (this.stat) {
            case 'physique':
                min = 10;
                max = 20;
                break;
            case 'blanche':
                min = 15;
                max = 25;
                break;
            case 'feu':
                min = 30;
                max = 40;
                break;
            case 'magique':
                min = 20;
                max = 40;
                break;
            case 'env':
                min = 10;
                max = 20;
                break;
            case 'explosion':
                min = 30;
                max = 50;
                break;
        }
        //picks a random number between 1 and 20
        this.rolled = !modifier
            ? Math.floor(Math.random() * (max - min + 1) + min)
            : Math.floor((Math.random() * (max - min + 1) + min + max) / 2);
        this.statValue = max;

        this.secret = secret;

        //Modifies passed and critic fields according to roll
        this.passed = true;
        this.critic = (this.rolled && (this.rolled == max)) as boolean;
        this.setText();
        this.setCSSClass();
    }
}
