import { JDR } from '@/types/Interfaces';
import { Character } from '@/types/Interfaces';

export interface IRoll {
    secret: boolean | undefined;
    CSSClass: string | undefined;
    text: string | undefined;
    timestamp: string;
    readonly jdr: JDR;
    readonly playerName: string;
}

export abstract class Roll implements IRoll{
    readonly stat: string;
    readonly timestamp: string;
    readonly jdr: JDR;
    readonly playerName: string;

    //true if the roll passed, false otherwise
    passed: boolean | undefined;
    //true if the roll is critical
    critic: boolean | undefined;
    //value of the stat
    statValue: number | undefined;
    //rolled value
    rolled: number | undefined;

    secret = false;

    CSSClass: string | undefined;
    text: string | undefined;

    protected constructor(stat: string, jdr: JDR, playerName: string) {
        this.stat = stat;
        this.timestamp = Date.now().toString();
        this.jdr = jdr;
        this.playerName = playerName;
    }

    /**
     * Returns the text to be displayed
     */
    public setText(): void {
        let res = `[${this.stat}] : ${this.rolled}/${this.statValue} `;

        if (this.passed) res += 'Succès ';
        else res += 'Echec ';

        if (this.critic) res += 'critique';

        if (this.passed) res += '!';
        else res += '...';

        this.text = res;
    }

    /**
     * Returns the CSS class-name for the roll
     */
    public setCSSClass(): void {
        if (this.passed) {
            if (this.critic) this.CSSClass = 'succesCritique';
            else this.CSSClass = 'succes';
        } else {
            if (this.critic) this.CSSClass = 'echecCritique';
            else this.CSSClass = 'echec';
        }
    }

    /**
     * Rolls "a die" and fills fields
     */
    abstract roll(character: Character | null, modifier: boolean, secret: boolean): void;
}