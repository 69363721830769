
import {Component, Vue} from 'vue-property-decorator';
import PadoCharSheetComponent from "@/components/ParceDomine/PadoCharSheetComponent.vue";
import {Game} from "@/types/Interfaces";
import {JDR} from "@/types/Interfaces";
import {onValue, ref} from "firebase/database";
import {db} from "@/firebase";
import AzrCharSheetComponent from "@/components/Azuria/AzrCharSheetComponent.vue";
import KimCharSheetComponent from "@/components/Kimakaan/KimCharSheetComponent.vue";

@Component({
    components: {
        KimCharSheetComponent,
        AzrCharSheetComponent,
        PadoCharSheetComponent,
    },
})

export default class CharSheet extends Vue {
    game: Game | null = null;
    id = "0";

    ParceDomine: JDR = JDR.ParceDomine;
    Azuria: JDR = JDR.Azuria;
    Kimakaan: JDR = JDR.Kimakaan;

    async created(): Promise<void> {
        this.id = this.$route.params.charId;
        try {
            const jdrsRef = ref(db, `/jdrs/${this.$route.params.gameId}`);
            await onValue(jdrsRef, (snapshot) => {
                this.game = snapshot.val();
            });
        } catch (error) {
            console.log('error : ' + error);
        }
    }
}
