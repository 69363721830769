import {IRoll, Roll} from '@/types/Roll';
import {DatabaseReference, ref, set} from 'firebase/database';
import {db} from '@/firebase';
import {PadoRoll} from '@/types/PadoRoll';

// WHEN ADDING A NEW JDR TYPE, ALSO ADD IT IN CreateGame.vue SELECT!
export enum JDR {
    undefined,
    ParceDomine,
    DameBlanche,
    Azuria,
    Kimakaan,
}

export interface User {
    id: string,
    isAdmin: boolean,
    isMJ: boolean,
    name: string,
}

export class Game {
    name: string;
    id: string;
    mjId: string;
    type: JDR;
    map: string;
    rolls: Roll[];
    characters: Character[];
    groups: string[];
    ref: DatabaseReference;
    npcCount: number;
    turnOrder: TurnOrder;

    async turnOrderaddCharacter(character: string, secret: boolean): Promise<void> {
        if (!this.turnOrder.characters) this.turnOrder.characters = [];
        if (this.turnOrder.characters.find(c => c.id == character)) return;
        this.turnOrder.characters.push({id: character, secret: secret, joker: true});
        if (!this.turnOrder.isStarted) {
            this.turnOrder.characters.sort((a, b) => {
                // @ts-ignore
                return this.characters[b.id].characteristics[4].value - this.characters[a.id].characteristics[4].value;
            });
        }
        await set(ref(db, `/jdrs/${this.id}/turnOrder/characters`), this.turnOrder.characters);
    }

    async turnOrderaddSecretCharacter(character: string): Promise<void> {
        // @ts-ignore
        const roll: PadoRoll = new PadoRoll('agilite', this.characters[character].name);
        // @ts-ignore
        await roll.roll(this.characters[character], true);
        if (!roll.passed) roll.secret = false;
        roll.text = `[Entrée combat discrète - agilite] ${roll.rolled}/${roll.statValue}`;
        await this.addRoll(roll);
        await this.turnOrderaddCharacter(character, roll.secret);
    }

    async turnOrderRemoveCharacter(character: string): Promise<void> {
        this.turnOrder.characters = this.turnOrder.characters.filter(c => c.id != character);
        await set(ref(db, `/jdrs/${this.id}/turnOrder/characters`), this.turnOrder.characters);
    }

    async turnOrderNext(): Promise<void> {
        if (this.turnOrder.isStarted) {
            // @ts-ignore
            this.turnOrder.characters.push(this.turnOrder.characters.shift());
            await set(ref(db, `/jdrs/${this.id}/turnOrder/characters`), this.turnOrder.characters);
        }
    }

    async turnOrderJoker(character: string): Promise<void> {
        const turnOrderCharacter = this.turnOrder.characters.find(c => c.id == character);
        if (turnOrderCharacter && turnOrderCharacter.joker) {
            turnOrderCharacter.joker = false;
            this.turnOrder.characters = this.turnOrder.characters.filter(c => c.id != character);
            this.turnOrder.characters = [turnOrderCharacter, ...this.turnOrder.characters];
            await set(ref(db, `/jdrs/${this.id}/turnOrder/characters`), this.turnOrder.characters);
        }
    }

    async turnOrderReveal(character: string): Promise<void> {
        const turnOrderCharacter = this.turnOrder.characters.find(c => c.id == character);
        if (turnOrderCharacter && turnOrderCharacter.secret) {
            turnOrderCharacter.secret = false;
            await set(ref(db, `/jdrs/${this.id}/turnOrder/characters`), this.turnOrder.characters);
        }
    }

    async turnOrderActivate(): Promise<void> {
        this.turnOrder.isActive = true;
        await set(ref(db, `/jdrs/${this.id}/turnOrder`), this.turnOrder);
    }

    async turnOrderDeactivate(): Promise<void> {
        this.turnOrder.isActive = false;
        this.turnOrder.characters = [];
        await set(ref(db, `/jdrs/${this.id}/turnOrder`), this.turnOrder);
    }

    async turnOrderStart(): Promise<void> {
        this.turnOrder.isStarted = true;
        await set(ref(db, `/jdrs/${this.id}/turnOrder`), this.turnOrder);
    }

    async turnOrderEnd(): Promise<void> {
        this.turnOrder.isStarted = false;
        this.turnOrder.characters = [];
        await set(ref(db, `/jdrs/${this.id}/turnOrder`), this.turnOrder);
    }

    async addRoll(roll: Roll): Promise<void> {
        if (this.rolls == undefined) this.rolls = [];
        this.rolls = [roll, ...this.rolls];
        this.rolls = this.rolls.slice(0,100);
        await set(ref(db, `/jdrs/${this.id}/rolls`), this.rolls);
    }

    constructor(game: Game) {
        this.name = game.name;
        this.id = game.id;
        this.mjId = game.mjId;
        this.type = game.type;
        this.map = game.map;
        this.rolls = game.rolls;
        this.characters = game.characters;
        this.groups = game.groups;
        this.ref = game.ref;
        this.npcCount = game.npcCount;
        this.turnOrder = game.turnOrder;
    }
}

export interface TurnOrder {
    isActive: boolean;
    isStarted: boolean;
    characters: {
        id: string;
        secret: boolean;
        joker: boolean;
    }[];
}

export interface Character {
    playerId: string;
    gameId: string;
    name: string;
    isNPC: boolean;
    isDisplayed: boolean;
    stats : {
        name: string;
        current: number;
        max: number;
    }[];
    characteristics: {
        name: string;
        value: number;
        color: string;
    }[];
    informations : {
        name: string;
        value: string | number;
    }[];
    rolls: IRoll[];
    category: string;
}

export interface PadoCharacter extends Character {
    roll: Roll[];
    abilities: Ability[];
    token : Token;
    inventory : Inventory;
}

export interface AzuriaCharacter extends Character {
    roll: Roll[];
    abilities: string[];
    inventory : Inventory;
}

export interface Ability {
    name: string;
    ri: number;
}

export interface Token {
    x: number;
    y: number;
    color: string;
    display: boolean;
    size: number;
}

export interface Inventory {
    itemSlots : number;
    items : InventoryItem[];
}

export interface InventoryItem {
    name: string;
    quantity: number;
    isWeapon: boolean;
    ammunition: number;
}
